import React from "react"
import CategoryWrapper from "../../components/CategoryWrapper"

function WomenTshirtsPage() {
  return (
    <CategoryWrapper
      seoTitle="T-shirts"
      gender="dame"
      category="tshirts"
    />
  )
}

export default WomenTshirtsPage
